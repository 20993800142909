import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';

import '@shopify/polaris/build/esm/styles.css';
import './index.css';
import './i18n'

const App = lazy(() => import('./App'));

import { BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux';
import { store } from './Reducers';
import { AppProvider, Frame } from '@shopify/polaris';
import translations from '@shopify/polaris/locales/en.json';

export const StoreDispatcher = React.createContext(store.dispatch);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
    <BrowserRouter basename={process.env.REACT_APP_ROUTE ?? ''}>
        <Provider store={store}>
            <AppProvider i18n={translations}>
                <StoreDispatcher.Provider value={store.dispatch}>
                    <Suspense fallback={<></>}>
                        <Frame>
                            <App />
                        </Frame>
                    </Suspense>
                </StoreDispatcher.Provider>
            </AppProvider>
        </Provider>
    </BrowserRouter>
);