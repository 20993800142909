import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import translationEN from './languages/english/en.json';
import translationHI from './languages/hindi/hn.json';
import translationJP from './languages/japanese/jp.json';
import translationGR from './languages/german/gr.json';
import translationFR from './languages/french/fr.json';
import translationAR from './languages/arabic/ar.json';
import translationSP from './languages/spanish/sp.json';
import translationPR from './languages/portguese/pr.json';

const resources = {
    en: {
        translation: translationEN
    },
    hn: {
        translation: translationHI
    },
    fr: {
        translation: translationFR
    },
    gr: {
        translation: translationGR
    },
    jp: {
        translation: translationJP
    },
    ar: {
        translation: translationAR
    },
    sp: {
        translation: translationSP
    },
    pr: {
        translation: translationPR
    }
}

i18n.use(initReactI18next).init({
    resources,
    lng: "en",//default language
    keySeparator: false,
    interpolation: {
        escapeValue: false
    }
});